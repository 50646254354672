import React, { FC } from "react";
import "./question.scss";

export type Type = "visited" | "not-visited" | "correct" | "wrong" | "marked";

interface QuestionProps {
	type: Type;
	nos: any;
	index: number;
	clickHandler: () => void;
}

const applyQuestionType = (type: Type) => {
	switch (type) {
		case "visited":
			return "question-visited";
		case "correct":
			return "question-correct";
		case "wrong":
			return "question-wrong";
		case "marked":
			return "question-marked"
		default:
			return "";
	}
};

const Question: FC<QuestionProps> = ({ nos, type, clickHandler }) => {
	return (
		<div
			className={`question__wrapper ${applyQuestionType(type)}`}
			onClick={clickHandler}
		>
			<span>{nos}</span>
		</div>
	);
};

export default Question;
