import { serializable, alias, primitive } from 'serializr';

export class ContehtHistoryUpdateModel {
  @serializable(alias('played_seconds', primitive()))
  playedSeconds?: number;
}

export class ContehtHistoryModel extends ContehtHistoryUpdateModel {
  @serializable(alias('id', primitive()))
  id?: string;
}
