import { Spin } from "antd";
import React, { FC } from "react";
import { LoadingOutlined } from '@ant-design/icons';

import "./loadingComponent.scss";
export const LoadingComponent: FC<{title?: string, className?: string}> = ({title, className}) => {
  return (
    <div className={`loading__container ${className}`}>
      <Spin tip={title ? title : "Fetching details..."} indicator={<LoadingOutlined spin/>}/>
    </div>
  );
};
