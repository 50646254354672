import { serializable, alias, primitive, object, list } from "serializr";

export class FileMetaModel {
    @serializable(alias("attachmentId", primitive()))
    attachmentId?: number;
  
    @serializable(alias("name", primitive()))
    name?: string;
  
    @serializable(alias("path", primitive()))
    path?: string;
  }

  export class CommentsModel {
    @serializable(alias("announcementId", primitive()))
    announcementId?: number;
    @serializable(alias("commentId", primitive()))
    commentId?: string;
    @serializable(alias("comment", primitive()))
    comment?: string;
    @serializable(alias("userType", primitive()))
    userType?: string;
    @serializable(alias("createdById", primitive()))
    createdById?: string;
    @serializable(alias("createdByName", primitive()))
    createdByName?: string;
    @serializable(alias("createdAt", primitive()))
    createdAt?: string;
  }

  export class AnnouncementModel {
    @serializable(alias("announcementId", primitive()))
    announcementId?: number;
    @serializable(alias("classId", primitive()))
    classId?: number;
    @serializable(alias("sectionId", primitive()))
    sectionId?: number;
    @serializable(alias("className", primitive()))
    className?: string;
    @serializable(alias("sectionName", primitive()))
    sectionName?: number;
    @serializable(alias("message", primitive()))
    message?: string;
    @serializable(alias("attachmentName", primitive()))
    attachmentName?: string;
    @serializable(alias("attachmentPath", primitive()))
    attachmentPath?: string;
    @serializable(alias("allowComment", primitive()))
    allowComment?: boolean;
    @serializable(alias("createdById", primitive()))
    createdById?: number;
    @serializable(alias("createdByName", primitive()))
    createdByName?: string;
    @serializable(alias("createdAt", primitive()))
    createdAt?: string;
    @serializable(alias("comments",list(object(CommentsModel))))
    comments?: CommentsModel[] = [] ;
  }

export class AnnouncementCreateModel {
  @serializable(alias("ClassId", primitive()))
  ClassId?: number;
  @serializable(alias("SectionId", primitive()))
  SectionId?: number;
  @serializable(alias("Message", primitive()))
  Message?: string;
  @serializable(alias("AllowComment", primitive()))
  AllowComment?: boolean;
}

export class FilterWorksheet {
  @serializable(alias("classId", primitive()))
  classId?: number;
  @serializable(alias("sectionId", primitive()))
  sectionId?: number;
  @serializable(alias("subjectId", primitive()))
  SubjectId?: number;
  @serializable(alias('CurrentPage', primitive()))
  CurrentPage?: number;
  @serializable(alias('Pagesize', primitive()))
  Pagesize?: number;
}
