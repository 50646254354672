import React, { FC } from "react";

export const secondsFormatConverter = (seconds: any) => {
  if (seconds) {
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60 );
    let s = Math.floor((seconds % 3600) % 60);
    return (
      <>
        {h}
        <span>h </span> {m}
        <span>m </span>
        {s}
        <span>s </span>
      </>
    );
  } else {
    return (
      <>
        0<span>h </span>0<span>m </span> 0<span>s </span>
      </>
    );
  }
};
