import React from 'react';
import { notification } from 'antd';
import { BellTwoTone } from '@ant-design/icons';
interface INotification {
    message: string;
    description: string;
    type: string;
    push?: boolean
}

type notificationType = "success" | "error" | "warning" | "info" | "open";

const Notification = ({ message, description, type, push }: INotification) => {
    return notification[type as notificationType]({
        message,
        description,
        icon: push && <BellTwoTone twoToneColor="#52c41a" className="animate__bell"/>
    })
};

export default Notification; 