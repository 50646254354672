import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as Functions from "../actions/question";

const mapStateToProps = (state: any, ownProps: any) => {
	return {
		questions: state.ques.questions,
		attempt: state.ques.attempt,
        currentQuestion: state.ques.currentQuestion,
        attempted: state.ques?.attempted,
        remaning: state.ques?.remaning,
		marked: state.ques?.marked,
		...ownProps,
	};
};
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(Functions, dispatch);

const QuestionContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default QuestionContainer;
