import React, { FC } from "react";
import ExamPannel from "../../../../shared/components/ExamPannel";

interface ExerciseExamProps {

}

const ExerciseExam: FC<ExerciseExamProps> = () => {
    return (
        <div className="exercise-exam__container">
            <ExamPannel />
        </div>
    )
}

export default ExerciseExam;