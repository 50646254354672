import { CreateReducer } from "../../shared/utils/createReducer";
import { SET_NAV_CONTENT } from "../definitions/titleConstants";

export interface ITitleState {
    showBack: boolean;
    title?: string;
    icon?: any;
    subText?: string;
    onSearch?: string;
    color?: string;
    searchText?: string;
    showSearch?: boolean;
    float?: boolean;
    twoBgs?: boolean;
}

const initState: ITitleState = {
    showBack: false,
    title: "",
    icon: null,
    subText: "",
    color: "#0C1013",
    searchText: "",
    showSearch: true,
    float: false,
    twoBgs: false,
    onSearch: ""
};

const titleReducer = CreateReducer(initState, {
    [SET_NAV_CONTENT](state: any, action: any) {
        return {
            ...state,
            ...action.payload, color: action?.payload?.color ?? "#0C1013", twoBgs: action?.payload?.twoBgs ?? false,
        };
    }
});

export default titleReducer;



